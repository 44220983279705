/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {api} from '../../../../../utils';
const Register = () => {
	const navigate = useNavigate();

	const [register, setRegister] = useState({
		input: {
			username: '',
			password: '',
			confirmPassword: '',
		},
	});

	const handleInputChange = (event) => {
		if (event.target.id === 'username') {
			setRegister((prevState) => ({
				...prevState,
				input: {
					...prevState.input,
					username: event.target.value,
				},
			}));
		}

		if (event.target.id === 'password') {
			setRegister((prevState) => ({
				...prevState,
				input: {
					...prevState.input,
					password: event.target.value,
				},
			}));
		}

		if (event.target.id === 'confirm-password') {
			setRegister((prevState) => ({
				...prevState,
				input: {
					...prevState.input,
					confirmPassword: event.target.value,
				},
			}));
		}
	};

	const RegisterButton = () => {
		if (
			register.input.username === '' &&
			register.input.password === '' &&
			register.input.confirmPassword === ''
		) {
			return toast.error(`You must enter your details !`);
		} else {
			if (register.input.password !== register.input.confirmPassword) {
				return toast.error(`Passwords are not the same`);
			}

			fetch(`${api.base}register`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					username: register.input.username,
					password: register.input.password,
				}),
			})
				.then((response) => response.json())
				.then((json) => {
					if (json.code === 200) {
						localStorage.setItem('token', json.token);
						toast.success(`Welcome Back ${json.username} !`);
						navigate('/');
					}

					if (json.code === 400) {
						toast.error(`You must complete the form !`);
					}

					if (json.code === 403) {
						toast.error(`Your account already exists !`);
					}
				})
				.catch((error) => {
					console.error('Error:', error);
				});
		}
	};
	return (
		<>
			<div className='max-w-xl mx-auto mt-6'>
				<div className='mt-5 w-full bg-secondary-600 p-4 rounded-lg '>
					<div className='p-2 mb-3'>
						<p>Username:</p>
						<div className=' py-2 bg-secondary-700 p-2 m-1'>
							<input
								className='appearance-none bg-transparent border-none w-full text-gray-400 mr-3 py-1 px-2 leading-tight focus:outline-none'
								type='text'
								placeholder='Username'
								autoComplete='username'
								id='username'
								onChange={handleInputChange}
							/>
						</div>
					</div>
					<div className='p-2 mb-3'>
						<p>Password:</p>
						<div className='py-2 bg-secondary-700 p-2 m-1'>
							<input
								className='appearance-none bg-transparent border-none w-full text-gray-400 mr-3 py-1 px-2 leading-tight focus:outline-none'
								type='password'
								placeholder='Password'
								autoComplete='off'
								id='password'
								onChange={handleInputChange}
							/>
						</div>
					</div>
					<div className='p-2 mb-3'>
						<p>Confirm Password:</p>
						<div className='py-2 bg-secondary-700 p-2 m-1'>
							<input
								className='appearance-none bg-transparent border-none w-full text-gray-400 mr-3 py-1 px-2 leading-tight focus:outline-none'
								type='password'
								placeholder='Confirm Password'
								autoComplete='off'
								id='confirm-password'
								onChange={handleInputChange}
							/>
						</div>
					</div>
				</div>

				<div className='mt-5 w-full bg-secondary-600 p-4 rounded-lg'>
					<button
						className='shadow shadow-green-500 bg-green-500 hover:bg-green-600 border-green-500 text-sm border-4 text-white py-2 px-3 w-full rounded font-bold text-lg tracking-wide'
						type='button'
						onClick={() => {
							RegisterButton();
						}}
					>
						Register
					</button>
				</div>
			</div>
		</>
	);
};

export default Register;
