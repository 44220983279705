/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {api} from '../../../../../utils';
const Login = () => {
	const navigate = useNavigate();

	const [login, setLogin] = useState({
		input: {
			username: '',
			password: '',
		},
		response: {
			code: 0,
		},
	});

	const handleInputChange = (event) => {
		if (event.target.id === 'username') {
			setLogin((prevState) => ({
				...prevState,
				input: {
					...prevState.input,
					username: event.target.value,
				},
			}));
		}

		if (event.target.id === 'password') {
			setLogin((prevState) => ({
				...prevState,
				input: {
					...prevState.input,
					password: event.target.value,
				},
			}));
		}

		console.log(login.input);
	};

	const LoginButton = () => {
		if (login.input.username === '' && login.input.password === '') {
			return toast.error(`You must enter your details !`);
		} else {
			fetch(`${api.base}login`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					username: login.input.username,
					password: login.input.password,
				}),
			})
				.then((response) => response.json())
				.then((json) => {
					if (json.code === 200) {
						localStorage.setItem('token', json.token);
						toast.success(`Welcome ${json.username} !`);
						navigate('/');
					}

					if (json.code === 400) {
						toast.error(`You must complete the form !`);
					}

					if (json.code === 403) {
						toast.error(
							`Your account does not exist or the information provided is incorrect !`
						);
					}
				})
				.catch((error) => {
					console.error('Error:', error);
				});
		}
	};

	return (
		<>
			<div className='max-w-xl mx-auto mt-6'>
				<div className='mt-5 w-full bg-secondary-600 p-4 rounded-lg '>
					<div className='p-2 mb-3'>
						<p>Username:</p>
						<div className=' py-2 bg-secondary-700 p-2 m-1'>
							<input
								className='appearance-none bg-transparent border-none w-full text-gray-400 mr-3 py-1 px-2 leading-tight focus:outline-none'
								type='text'
								id='username'
								placeholder='Username'
								autoComplete='username'
								onChange={handleInputChange}
							/>
						</div>
					</div>
					<div className='p-2 mb-3'>
						<p>Password:</p>
						<div className='py-2 bg-secondary-700 p-2 m-1'>
							<input
								className='appearance-none bg-transparent border-none w-full text-gray-400 mr-3 py-1 px-2 leading-tight focus:outline-none'
								type='password'
								id='password'
								placeholder='Password'
								autoComplete='off'
								onChange={handleInputChange}
							/>
						</div>
					</div>
				</div>

				<div className='mt-5 w-full bg-secondary-600 p-4 rounded-lg'>
					<button
						className='shadow shadow-green-500 bg-green-500 hover:bg-green-600 border-green-500  border-4 text-white py-2 px-3 w-full rounded font-bold text-lg tracking-wide'
						type='button'
						onClick={() => {
							LoginButton();
						}}
					>
						Login
					</button>
				</div>
			</div>
		</>
	);
};

export default Login;
