import React, {useState, useEffect} from 'react';
import {useMusicProvider} from '../../musicBoard/context';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faEllipsis,
	faPlay,
	faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {fetchData, api} from '../../../utils';
import {useParams} from 'react-router-dom';
import ModalSong from '../../../components/features/modalSong';
import {useNavigate} from 'react-router-dom';

const Playlist = () => {
	const {id} = useParams();
	const navigate = useNavigate();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);
	const [songInformation, setSongInformation] = useState({
		title: null,
		author: null,
		thumbnail: null,
		musicId: null,
		playlist: null,
	});

	const {setMusicBoard} = useMusicProvider();
	const [playlist, setPlaylist] = useState({
		playlistRow: {},
		playlistInfo: {},
	});

	const fetchRow = async () => {
		const getData = await fetchData('playlist/' + id, 'GET', null, {
			authorization: localStorage.getItem('token'),
		});
		if (getData.code === 200) {
			setPlaylist((prevState) => ({
				...prevState,
				playlistRow: getData.song,
				playlistInfo: getData.playlist,
			}));
			console.log(playlist);
		}
	};

	useEffect(() => {
		fetchRow();
	}, []);

	const AddPlaylist = async (id, startBySongId = null) => {
		const getData = await fetchData('playlist/' + id, 'GET', null, {
			authorization: localStorage.getItem('token'),
		});

		localStorage.setItem(
			'playlistNow',
			JSON.stringify(playlist.playlistRow)
		);

		setMusicBoard((prevState) => ({
			...prevState,
			musicId: startBySongId
				? startBySongId
				: playlist.playlistRow[0].songId,
			playlist: id,
			playlistSong: playlist.playlistRow,
		}));
	};

	const MusicSettings = async (item) => {
		setSongInformation({
			title: item.songTitle,
			author: item.songAuthor,
			thumbnail: `${api.thumbnail}${item.songId}`,
			musicId: item.songId,
			playlist: playlist.playlistInfo.id,
		});
		openModal();
	};
	return (
		<>
			<div>
				{/* First Section with Background Image and Fade Effect */}
				<div className='relative h-[400px]'>
					<div
						className='absolute inset-0 bg-cover bg-center bg-no-repeat'
						style={{
							backgroundImage: `url('${api.thumbnail}${playlist.playlistInfo.lastSongId}')`,
						}}
					>
						<div className='absolute inset-0 bg-gradient-to-b from-transparent to-secondary-700 opacity-90'></div>
					</div>

					<div className='relative z-10 flex flex-col justify-center items-center h-full text-center text-white'>
						<div className='absolute top-4 left-4'>
							<FontAwesomeIcon
								icon={faChevronLeft}
								className='text-white text-2xl'
								onClick={() => {
									navigate('/library');
								}}
							/>
						</div>

						<h2 className='text-2xl font-bold mb-5'>
							{playlist.playlistInfo.name}
						</h2>
						<button
							onClick={() => {
								AddPlaylist(playlist.playlistInfo.id);
							}}
							className='bg-green-500 hover:bg-green-600 text-lg border-4 border-green-500 text-white font-semibold py-2 px-12 rounded-lg'
						>
							<FontAwesomeIcon icon={faPlay} className='mr-2' />
							<span>Play Playlist</span>
						</button>
					</div>
				</div>

				{/* Second Section */}
				<div className='space-y-3 pt-4'>
					{playlist.playlistRow.length >= 1 &&
						playlist.playlistRow.map((item, key) => (
							<a
								key={key}
								className='flex items-center p-3 text-base font-bold rounded-lg bg-secondary-700'
								onClick={() => {
									AddPlaylist(item.id, item.songId);
								}}
							>
								<div className='flex items-center m-4 cursor-pointer select-none'>
									<div className='flex flex-col'>
										<h3 className='text-lg font-semibold break-words hyphens-auto max-w-64'>
											{item.songTitle}
										</h3>
										<p className='text-sm text-gray-400'>
											{item.songAuthor}
										</p>
									</div>
								</div>
								<span
									className='ml-auto inline-flex items-center justify-center px-2 py-0.5 text-xs font-medium text-gray-500 rounded'
									onClick={(e) => {
										e.preventDefault();
										MusicSettings(item);
									}}
								>
									<FontAwesomeIcon
										icon={faEllipsis}
										className='text-[2rem]'
									/>
								</span>
							</a>
						))}
				</div>
			</div>
			<ModalSong
				isOpen={isModalOpen}
				onClose={closeModal}
				musicInformation={songInformation}
				eventCallback={fetchRow}
			/>
		</>
	);
};

export default Playlist;
