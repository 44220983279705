import React, {useState, useEffect} from 'react';
import Navbar from './components/features/navbar';
import MusicBoard from './components/features/musicBoard/musicBoard';
import {MusicProvider} from './components/features/musicBoard/context';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from 'react-router-dom';
import {api} from './components/utils';
const App = (props) => {
	const [Authorized, setAuthorized] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const checkAuth = async () => {
			try {
				fetch(`${api.base}@me`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						authorization: localStorage.getItem('token'),
					},
				})
					.then((response) => response.json())
					.then((json) => {
						if (json.code === 200) {
							setAuthorized(true);
						}

						if (json.code === 401) {
							setAuthorized(false);
							localStorage.removeItem('token');
							navigate('/account');
						}
					})
					.catch((error) => {
						console.error('Error:', error);
					});
			} catch (error) {
				console.error(error);
			}
		};

		checkAuth();
	}, [location.pathname]);

	if (!Authorized && location.pathname !== '/account') {
		return;
	}

	return (
		<MusicProvider>
			<ToastContainer theme='dark' />
			<div className='min-h-screen flex flex-col bg-black  md:pb-[150px] pb-[250px]'>
				<Navbar />
				<MusicBoard />
				<main className='flex-1 p-4 md:p-10 mt-3 md:mt-20 text-white'>
					{props.component}
				</main>
			</div>
		</MusicProvider>
	);
};

export default App;
