import React, {useState, useEffect} from 'react';
import {useMusicProvider} from '../musicBoard/context';
const Library = () => {
	const {setMusicBoard} = useMusicProvider();

	return (
		<div
			className='w-full h-48 relative bg-cover bg-center flex justify-center items-center'
			style={{
				backgroundImage:
					"url('https://i.ytimg.com/vi/xk1c02v3U_U/hq720.jpg?sqp=-…ADIQj0AgKJD&rs=AOn4CLDDt5Rn4-0YBqfzNhyEC7EVdVbpyg')", // Remplace 'ton-image.jpg' par l'URL de ton image
			}}
		>
			<div className='absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-80'></div>
			<h2 className='text-5xl	 font-semibold text-white z-10 uppercase'>
				Ziak
			</h2>
		</div>
	);
};

export default Library;
