/* eslint-disable react/no-unescaped-entities */
import React, {useEffect, useState} from 'react';
import {useMusicProvider} from '../../musicBoard/context';
import {toast} from 'react-toastify';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {api, fetchData} from '../../../utils';
import Modal from '../../../components/modal';
import {
	faPlus,
	faHeart,
	faCirclePlay,
	faUser,
	faMusic,
} from '@fortawesome/free-solid-svg-icons';
const ModalCreatePlaylist = ({isOpen, onClose, event}) => {
	const {musicBoard} = useMusicProvider();
	const [modalInformation, setModalInformation] = useState({
		inputNamePlaylist: 'Enter the name of your playlist',
	});
	const handleInputChange = async (event) => {
		setModalInformation((prevState) => ({
			...prevState,
			inputNamePlaylist: event.target.value,
		}));
	};

	const handleSubmit = async () => {
		const getData = await fetchData(
			'playlist',
			'POST',
			{name: modalInformation.inputNamePlaylist},
			{
				authorization: localStorage.getItem('token'),
			}
		);
		if (getData.code === 200) {
			toast.success('Succefully Create the Playlist !');
			event?.();
			onClose();
		}
		if (getData.code === 409) {
			return toast.error(`${getData.error}`);
		}
	};
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<div className='p-6 flex flex-col h-full justify-between'>
				<div className='flex flex-col items-center flex-grow justify-center'>
					<div className='text-center mb-4'>
						<span className='font-semibold text-lg'>
							{modalInformation.inputNamePlaylist}
						</span>
					</div>
					<input
						onChange={handleInputChange}
						type='text'
						maxLength={32}
						placeholder='Enter the name of your playlist'
						className='w-full bg-secondary-700 p-4 m-4 border rounded-md border-none w-full text-gray-400 leading-tight focus:outline-none'
					/>
				</div>

				<button
					className='w-full py-4 bg-green-500 text-white font-semibold rounded-md hover:bg-green-600 transition duration-200'
					onClick={() => {
						handleSubmit();
					}}
				>
					Create the playlist
				</button>
			</div>
		</Modal>
	);
};

export default ModalCreatePlaylist;
