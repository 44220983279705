import React, {useEffect} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faForward,
	faBackward,
	faPlay,
	faPause,
	faRepeat,
	faShuffle,
	faSpinner,
	faVolumeHigh,
} from '@fortawesome/free-solid-svg-icons';
import {useMusicProvider} from '../context';
import {PlayHandler, NextTrack, ForwardTrack} from '../function/audio';

const ProgressBar = ({audioRef}) => {
	const {musicBoard, setMusicBoard} = useMusicProvider();

	const togglePlayPause = async () => {
		if (musicBoard.canPlay === false) return false;
		try {
			PlayHandler({
				audioRef: audioRef,
				musicBoard: musicBoard,
				setMusicBoard: setMusicBoard,
			});
		} catch (error) {
			console.error('Error playing or pausing audio:', error);
		}
	};

	const handleSeek = (e) => {
		const seekTime = e.target.value;
		audioRef.current.currentTime = seekTime;
		setMusicBoard((prevState) => ({
			...prevState,
			currentTime: seekTime,
			progress: (seekTime / musicBoard.videoInformation.duration) * 101,
		}));
	};

	const repeatSong = () => {
		setMusicBoard((prevState) => ({
			...prevState,
			infinite: !prevState.infinite,
		}));
	};

	const shufflePlaylist = () => {
		setMusicBoard((prevState) => ({
			...prevState,
			shuffle: !prevState.shuffle,
		}));
	};

	const nextSong = () => {
		NextTrack({audioRef, musicBoard, setMusicBoard});
	};

	const forwardSong = () => {
		ForwardTrack({audioRef, musicBoard, setMusicBoard});
	};

	useEffect(() => {
		const userAgent =
			navigator.userAgent || navigator.vendor || window.opera;

		if (
			/android/i.test(userAgent) ||
			(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
		) {
			setMusicBoard((prevState) => ({
				...prevState,
				isMobile: true,
			}));
		} else {
			setMusicBoard((prevState) => ({
				...prevState,
				isMobile: false,
			}));
		}
	}, []);

	const formatTime = (time) => {
		const minutes = Math.floor(time / 60);
		const seconds = Math.floor(time % 60);
		return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
	};

	const handleVolumeChange = (event) => {
		const newVolume = event.target.value;
		setMusicBoard((prevState) => ({
			...prevState,
			volume: newVolume,
		}));
		if (audioRef.current) {
			audioRef.current.volume = newVolume;
		}
	};

	function formatVolume(num) {
		const str = num.toString();
		return str.slice(0, 3).replace('.', '');
	}

	return (
		<>
			<div
				className={`fixed bottom-10 rounded-lg left-4 right-4 text-white p-4 flex flex-col items-center space-y-2 z-50 ${
					musicBoard.isExpanded ? 'h-auto' : 'hidden'
				}`}
			>
				<div className='flex items-center justify-between w-full md:w-2/4 mt-4 p-2'>
					<span>{formatTime(musicBoard.currentTime)}</span>

					<div className='relative flex-1 mx-4 w-full h-2 bg-secondary-700 rounded-lg'>
						<div
							className='absolute top-0 left-0 h-2 bg-green-500 rounded-lg'
							style={{
								width: `${
									(musicBoard.currentTime /
										musicBoard.videoInformation.duration) *
									100
								}%`,
							}}
						></div>

						<input
							type='range'
							className='absolute top-0 left-0 w-full h-2 opacity-0 cursor-pointer'
							min='0'
							max={musicBoard.videoInformation.duration}
							value={musicBoard.currentTime}
							onChange={handleSeek}
						/>
					</div>

					<span>{formatTime(musicBoard.duration)}</span>
				</div>

				<div className={`flex items-center justify-center w-full mt-2`}>
					<button
						className='text-white p-4 rounded-full transition duration-200 ease-in-out'
						onClick={() => {
							repeatSong();
						}}
					>
						<FontAwesomeIcon
							icon={faRepeat}
							className={`text-[1.5rem] ${
								musicBoard.infinite && 'text-green-500'
							}`}
						/>
					</button>
					<button
						className='text-white p-4 rounded-full transition duration-200 ease-in-out'
						onClick={() => {
							forwardSong();
						}}
					>
						<FontAwesomeIcon
							icon={faBackward}
							className='text-[2.5rem]'
						/>
					</button>
					<button
						onClick={() => {
							togglePlayPause();
						}}
						className={`${
							musicBoard.canPlay ? 'text-white' : 'text-gray-700'
						} p-4 rounded-full transition duration-200 ease-in-out`}
					>
						{musicBoard.canPlay ? (
							<FontAwesomeIcon
								icon={musicBoard.isPlaying ? faPause : faPlay}
								className='text-[2.5rem]'
							/>
						) : (
							<FontAwesomeIcon
								icon={faSpinner}
								className='animate-spin text-white text-[2.5rem]'
							/>
						)}
					</button>
					<button
						className='text-white p-4 rounded-full transition duration-200 ease-in-out'
						onClick={() => {
							nextSong();
						}}
					>
						<FontAwesomeIcon
							icon={faForward}
							className='text-[2.5rem]'
						/>
					</button>
					<button
						className='text-white p-4 rounded-full transition duration-200 ease-in-out'
						onClick={() => {
							shufflePlaylist();
						}}
					>
						<FontAwesomeIcon
							icon={faShuffle}
							className={`text-[1.5rem] ${
								musicBoard.shuffle && 'text-green-500'
							}`}
						/>
					</button>
				</div>
				{musicBoard.isMobile === false && (
					<div className='flex items-center justify-between w-1/4	 mt-4 p-2'>
						<FontAwesomeIcon
							icon={faVolumeHigh}
							className='text-[1.5rem]'
						/>
						<div className='relative flex-1 mx-4 w-full h-2 bg-secondary-700 rounded-lg'>
							<div
								className='absolute top-0 left-0 h-2 bg-green-500 rounded-lg'
								style={{
									width: `${(musicBoard.volume / 1) * 100}%`,
								}}
							></div>

							<input
								type='range'
								className='absolute top-0 left-0 w-full h-2 opacity-0 cursor-pointer'
								min='0'
								max='1'
								step='0.01'
								value={musicBoard.volume}
								onChange={handleVolumeChange}
							/>
						</div>
						<span>
							{formatVolume((musicBoard.volume / 1) * 100)}%
						</span>
					</div>
				)}
			</div>
		</>
	);
};

export default ProgressBar;
