/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Auth from './components/auth/auth';

const Account = () => {
	return (
		<>
			<Auth />
		</>
	);
};

export default Account;
