import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faEllipsis} from '@fortawesome/free-solid-svg-icons';
import {useMusicProvider} from '../context';

const TopBar = ({openModal}) => {
	const {setMusicBoard} = useMusicProvider();

	const handleClick = () => {
		setMusicBoard((prevState) => ({
			...prevState,
			isExpanded: !prevState.isExpanded,
		}));
	};

	return (
		<>
			<div className={`flex items-center justify-between w-full`}>
				<button
					onClick={handleClick}
					className={`text-white p-4 rounded-full text-left transition duration-200 ease-in-out`}
				>
					<FontAwesomeIcon
						icon={faAngleDown}
						className='text-[2rem]'
					/>
				</button>

				<div className='flex items-center justify-center'></div>
				<button
					onClick={(e) => {
						e.stopPropagation();
						openModal();
					}}
					className={`text-white p-4 rounded-full text-right transition duration-200 ease-in-out`}
				>
					<FontAwesomeIcon
						icon={faEllipsis}
						className='text-[2rem]'
					/>
				</button>
			</div>
		</>
	);
};

export default TopBar;
