import React, {useEffect} from 'react';
import {api} from '../../../utils';
export async function PlayAudio(audioRef) {
	try {
		await audioRef.current.play();
	} catch (error) {
		return error;
	}
}

export function PauseAudio(audioRef) {
	audioRef.current.pause();
}

export async function setCanPlayAndStart({
	audioRef,
	musicBoard,
	setMusicBoard,
}) {
	setMusicBoard((prevState) => ({
		...prevState,
		canPlay: true,
		autoPlayNext: true,
	}));

	if (musicBoard.autoPlayNext === true) {
		await PlayAudio(audioRef);
	}
}

export function ForwardTrack({audioRef, musicBoard, setMusicBoard}) {
	const itemsArray = musicBoard.lastSong;

	if (Array.isArray(itemsArray) && itemsArray.length > 0) {
		const lastItem = itemsArray[itemsArray.length - 1];
		const updatedItems = itemsArray.slice(0, -1);
		setMusicBoard((prevState) => ({
			...prevState,
			musicId: lastItem,
			lastSong: updatedItems,
		}));
		console.log(updatedItems);
	}
}

export function NextTrack({audioRef, musicBoard, setMusicBoard}) {
	const storedItems = localStorage.getItem('items');
	const storedPlaylist = localStorage.getItem('playlistNow');
	if (!storedItems) {
		localStorage.setItem('items', '[]');
	}
	if (storedItems) {
		const itemsArray = JSON.parse(storedItems);
		if (itemsArray.length >= 1) {
			const firstItem = itemsArray[0];
			setMusicBoard((prevState) => ({
				...prevState,
				musicId: firstItem,
			}));

			const updatedItems = itemsArray.slice(1);
			localStorage.setItem('items', JSON.stringify(updatedItems));
		}

		if (itemsArray.length === 0) {
			const playlistArray = JSON.parse(storedPlaylist);
			console.log(playlistArray);
			if (playlistArray.length >= 1) {
				if (musicBoard.shuffle === false) {
					console.log('===========', playlistArray);
					const updatedSongs = [
						...playlistArray.slice(1),
						playlistArray[0],
					];
					localStorage.setItem(
						'playlistNow',
						JSON.stringify(updatedSongs)
					);

					setMusicBoard((prevState) => ({
						...prevState,
						musicId: updatedSongs[0].songId,
					}));
				} else {
					const shuffleArray = (array) => {
						for (let i = array.length - 1; i > 0; i--) {
							const j = Math.floor(Math.random() * (i + 1));
							[array[i], array[j]] = [array[j], array[i]];
						}
						return array;
					};

					const updatedSongs = shuffleArray([...playlistArray]);

					localStorage.setItem(
						'playlistNow',
						JSON.stringify(updatedSongs)
					);

					setMusicBoard((prevState) => ({
						...prevState,
						musicId: updatedSongs[0].songId,
					}));
				}
			}

			if (playlistArray.length === 0 || playlistArray === null) {
				setMusicBoard((prevState) => ({
					...prevState,
					isPlaying: false,
					canPlay: true,
				}));
			}
		}
	}
}

export function onAudioEnded({audioRef, musicBoard, setMusicBoard}) {
	if (musicBoard.infinite === true) {
		audioRef.current.currentTime = 0;
		setMusicBoard((prevState) => ({
			...prevState,
			progress: 0,
			currentTime: 0,
			duration: 0,
			canPlay: true,
		}));
		audioRef.current.play();
	}

	setMusicBoard((prevState) => {
		const {musicId, lastestSong, lastSong} = prevState;

		if (!lastSong.includes(musicId)) {
			return {
				...prevState,
				lastSong: [...lastSong, musicBoard.musicId],
			};
		}
		return prevState;
	});

	if (musicBoard.infinite === false) {
		NextTrack({
			audioRef: audioRef,
			setMusicBoard: setMusicBoard,
			musicBoard: musicBoard,
		});
	}
}

export function PlayHandler({audioRef, musicBoard, setMusicBoard}) {
	if (musicBoard.isPlaying) {
		PauseAudio(audioRef);
	} else {
		setTimeout(async () => {
			await PlayAudio(audioRef);
		}, 200);
	}

	setMusicBoard((prevState) => ({
		...prevState,
		isPlaying: !prevState.isPlaying,
	}));
}

export function updateTime({audioRef, setMusicBoard, musicBoard}) {
	const currentTime = audioRef.current.currentTime;
	const duration = musicBoard.videoInformation.duration;
	setMusicBoard((prevState) => ({
		...prevState,
		currentTime: currentTime,
		duration: musicBoard.videoInformation.duration,
		progress: (currentTime / duration) * 100,
	}));
}

export function updateMediaSession({audioRef, setMusicBoard, musicBoard}) {
	if (
		musicBoard.videoInformation.title &&
		musicBoard.videoInformation.author &&
		musicBoard.videoInformation.thumbnail
	) {
		if ('mediaSession' in navigator) {
			navigator.mediaSession.metadata = new MediaMetadata({
				title: musicBoard.videoInformation.title,
				artist: musicBoard.videoInformation.author,
				artwork: [
					{
						src: `${api.thumbnail}${musicBoard.musicId}`,
						sizes: '512x512',
						type: 'image/jpeg',
					},
				],
			});

			navigator.mediaSession.setActionHandler('play', async () => {
				setMusicBoard((prev) => ({...prev, isPlaying: true}));
				await PlayAudio(audioRef);
				navigator.mediaSession.playbackState = 'playing';
			});

			navigator.mediaSession.setActionHandler('pause', async () => {
				setMusicBoard((prev) => ({...prev, isPlaying: false}));
				PauseAudio(audioRef);
				navigator.mediaSession.playbackState = 'paused';
			});

			navigator.mediaSession.setActionHandler('seekto', (details) => {
				const seekTime = details.seekTime;
				if (audioRef.current) {
					audioRef.current.currentTime = seekTime;
				}
			});

			navigator.mediaSession.setActionHandler('nexttrack', () => {
				NextTrack({audioRef, musicBoard, setMusicBoard});
			});
		}
	}
}
