import React, {useState, useEffect} from 'react';
import {useMusicProvider} from '../musicBoard/context';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faBook, faMusic} from '@fortawesome/free-solid-svg-icons';
import {fetchData, api} from '../../utils';
import {useParams} from 'react-router-dom';
import PlaylistList from './components/list';
import Playlist from './components/playlist';

const Library = () => {
	const {id} = useParams();

	return <>{id === undefined ? <PlaylistList /> : <Playlist />}</>;
};

export default Library;
