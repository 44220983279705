import React, {useState, useEffect} from 'react';
import LatestSongArtist from './components/lastestSongArtist';
import {useMusicProvider} from '../musicBoard/context';
import {api} from '../../utils';
const Dashboard = () => {
	const {setMusicBoard} = useMusicProvider();
	const [dashboard, setDashboard] = useState({
		artist: {},
		song: {},
	});

	const playOnce = (song) => {
		fetch(`${api.base}search/once`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: localStorage.getItem('token'),
			},
			body: JSON.stringify({search: song}),
		})
			.then((response) => response.json())
			.then((json) => {
				if (json.code === 200) {
					setMusicBoard((prevState) => ({
						...prevState,
						musicId: json.song.musicId,
					}));
				}
			});
	};

	useEffect(() => {
		fetch(`${api.base}chart`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				authorization: localStorage.getItem('token'),
			},
		})
			.then((response) => response.json())
			.then((json) => {
				if (json.code === 200) {
					setDashboard((prevState) => ({
						...prevState,
						artist: json.artist,
						song: json.tracks,
					}));

					localStorage.setItem(
						'chart',
						JSON.stringify({
							artist: json.artist,
							song: json.tracks,
						})
					);
				}
			})
			.catch((error) => {
				if (localStorage.getItem('chart')) {
					setDashboard((prevState) => ({
						...prevState,
						artist: JSON.parse(localStorage.getItem('chart'))[
							'artist'
						],
						song: JSON.parse(localStorage.getItem('chart'))['song'],
					}));
				}
			});
	}, []);

	return (
		<div className='max-w-4xl mx-auto'>
			<h2 className='text-xl font-semibold mb-4'>Bienvenue Anatik 👋!</h2>
			<LatestSongArtist />
			<LatestSongArtist />

			<h2 className='text-xl font-semibold p-2'>Some artist</h2>
			<div className='w-full overflow-x-scroll md:gap-5 md:overflow-x-hidden md:flex md:justify-center md:items-center whitespace-nowrap p-4'>
				{dashboard.artist.length >= 1 &&
					dashboard.artist.slice(0, 6).map((item, key) => (
						<div
							className='inline-block w-32 h-32 md:h-44	m-2'
							key={key}
						>
							<div className='max-w-sm rounded  '>
								<div className='flex justify-center items-center'>
									<img
										className='rounded-full h-28 w-28 min-w-28 max-w-28 min-h-28 max-h-28 md:h-full	md:w-full aspect-square	 object-cover'
										src={item.picture}
										alt='Sunset in the mountains'
									/>
								</div>
								<div className='py-4'>
									<div className='font-bold text-xl text-center mb-2 block h-8 truncate'>
										{item.title}
									</div>
								</div>
							</div>
						</div>
					))}
			</div>

			<h2 className='text-xl font-semibold p-2'>Top 10 Song</h2>
			<ul className='my-4 space-y-3'>
				{dashboard.song.length >= 1 &&
					dashboard.song.slice(0, 10).map((item, key) => (
						<li key={key}>
							<a
								className='flex items-center p-3 text-base font-bold  rounded-lg bg-secondary-700'
								onClick={() => {
									playOnce(`${item.title} - ${item.artist}`);
								}}
							>
								<div className='flex items-center m-4 cursor-pointer select-none'>
									<img
										src={item.picture}
										alt={`thumnail-${key}`}
										className='w-14 h-14 mr-3 object-cover rounded-lg'
									/>

									<div className='flex flex-col'>
										<h3 className='text-lg font-semibold break-words hyphens-auto max-w-64'>
											{item.title}
										</h3>
										<p className='text-sm text-gray-400'>
											{item.artist}
										</p>
									</div>
								</div>
							</a>
						</li>
					))}
			</ul>
		</div>
	);
};

export default Dashboard;
