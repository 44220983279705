import React from 'react';
import {useMusicProvider} from '../context';

const Background = () => {
	const {musicBoard} = useMusicProvider();

	return (
		<>
			<div
				className={`w-full h-full absolute -z-10`}
				style={{
					backgroundColor:
						musicBoard.isExpanded && musicBoard.bgColor,
				}}
			>
				<img
					className='absolute w-full h-full object-cover opacity-60 scale-110 blur-md'
					src={musicBoard.videoInformation.thumbnail}
					alt='thumbnail'
				/>
			</div>
		</>
	);
};

export default Background;
