import React, {useState, useEffect} from 'react';
import {
	faEllipsis,
	faMagnifyingGlass,
	faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import {toast} from 'react-toastify';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useMusicProvider} from '../../features/musicBoard/context';
import {fetchData, api} from '../../utils';
import ModalSong from '../../components/features/modalSong';
const Search = () => {
	const [inputValue, setInputValue] = useState('');
	const {setMusicBoard} = useMusicProvider();
	const [response, setResponse] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [onSearch, setonSearch] = useState(false);
	const [songInformation, setSongInformation] = useState({
		title: null,
		author: null,
		thumbnail: null,
		musicId: null,
	});

	const AddNextSearch = (musicInfo) => {
		const storedItems = localStorage.getItem('last-search');
		let itemsArray = storedItems ? JSON.parse(storedItems) : [];
		const existingIndex = itemsArray.findIndex(
			(item) => item.musicId === musicInfo.id
		);
		if (existingIndex !== -1) {
			itemsArray.splice(existingIndex, 1);
		}
		itemsArray.unshift({
			musicId: musicInfo.id,
			title: musicInfo.title,
			author: musicInfo.author,
			thumbnail: musicInfo.thumbnail,
			duration: musicInfo.duration,
		});
		if (itemsArray.length > 20) {
			itemsArray = itemsArray.slice(0, 20);
		}
		localStorage.setItem('last-search', JSON.stringify(itemsArray));
	};

	useEffect(() => {
		const getSearchHistory = () => {
			const storedItems = localStorage.getItem('last-search');
			let itemsArray = storedItems ? JSON.parse(storedItems) : [];
			setResponse(itemsArray);
		};

		getSearchHistory();
	}, []);

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
	};
	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);
	const changeTrack = (musicInfo) => {
		setMusicBoard((prevState) => ({
			...prevState,
			musicId: musicInfo.id,
			videoInformation: {
				title: musicInfo.title,
				author: musicInfo.author,
				thumbnail: musicInfo.thumbnail,
				duration: musicInfo.duration,
			},
		}));
		AddNextSearch(musicInfo);
	};

	const musicSettings = (e, item) => {
		e.stopPropagation();

		setSongInformation((prevState) => ({
			...prevState,
			title: item.title,
			author: item.author,
			thumbnail: `${api.thumbnail}${item.musicId}`,
			musicId: item.musicId,
		}));
		openModal();
	};

	const handleSubmit = () => {
		if (onSearch === false) {
			if (inputValue === '')
				return toast.error(`Please enter a value in the search field.`);
			setResponse([]);
			setonSearch(true);
			fetch(`${api.base}search`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: localStorage.getItem('token'),
				},
				body: JSON.stringify({search: inputValue}),
			})
				.then((response) => {
					if (!response.ok) {
						setonSearch(false);
						throw new Error('Network response was not ok');
					}
					return response.json();
				})
				.then((data) => {
					if (data.code === 200) {
						setResponse(data.row);
						console.log(response);
						return setonSearch(false);
					}
					if (data.code !== 200) setonSearch(false);
				})
				.catch((error) => {
					console.error('Error:', error);
					setonSearch(false);
				});
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleSubmit();
		}
	};
	return (
		<>
			<div className='fixed top-0 md:top-16 left-0 bg-black w-full bg-opacity-60 p-4'>
				<div className='flex items-center border-b border-green-500 py-2 bg-secondary-700 p-2 m-1'>
					<input
						className='appearance-none bg-transparent border-none w-full text-gray-400 mr-3 py-1 px-2 leading-tight focus:outline-none'
						type='text'
						placeholder='Song Or Artist'
						onChange={handleInputChange}
						onKeyDown={handleKeyDown}
					/>
					<button
						className='flex-shrink-0 bg-green-500 hover:bg-green-600 border-green-500 text-sm border-4 text-white font-semibold py-2 px-3 rounded'
						type='button'
						onClick={handleSubmit}
					>
						{onSearch === true ? (
							<FontAwesomeIcon
								icon={faSpinner}
								className='animate-spin h-5 w-5'
							/>
						) : (
							<>
								<FontAwesomeIcon
									icon={faMagnifyingGlass}
									className='h-4 w-4 mr-1'
								/>
								Search
							</>
						)}
					</button>
				</div>
			</div>

			<ul className='my-4 space-y-3 mt-20'>
				{onSearch === true && (
					<>
						{[...Array(3)].map((_, index) => (
							<li key={`skeleton-${index}`}>
								<a className='flex items-center p-3 text-base font-bold rounded-lg bg-secondary-700'>
									<div className='flex items-center m-4 cursor-pointer select-none'>
										<div className='w-14 h-14 mr-3 rounded-lg bg-secondary-500 animate-pulse'></div>

										<div className='flex flex-col'>
											<h3 className='w-32 h-4 bg-secondary-500 animate-pulse rounded-md mb-2'></h3>
											<div className='w-20 h-4 bg-secondary-500 animate-pulse rounded-md'></div>
										</div>
									</div>
								</a>
							</li>
						))}
					</>
				)}

				{response.length >= 1 &&
					response.map((item, key) => (
						<li key={key}>
							<a
								onClick={() => {
									changeTrack({
										id: item.musicId,
										title: item.title,
										author: item.author,
										thumbnail: `${api.thumbnail}${item.musicId}`,
										duration: item.duration,
									});
								}}
								className='flex items-center p-3 text-base font-bold  rounded-lg bg-secondary-700'
							>
								<div className='flex items-center m-4 cursor-pointer select-none'>
									<img
										src={`${api.thumbnail}${item.musicId}`}
										alt={`thumnail-${item.musicId}`}
										className='w-14 h-14 mr-3 object-cover rounded-lg'
									/>

									<div className='flex flex-col'>
										<h3 className='text-lg font-semibold break-words hyphens-auto max-w-64'>
											{item.title}
										</h3>
										<p className='text-sm text-gray-400'>
											{item.author}
										</p>
									</div>
								</div>
								<span
									className='ml-auto inline-flex items-center justify-center px-2 py-0.5 text-xs font-medium text-gray-500 rounded'
									onClick={(e) => musicSettings(e, item)}
								>
									<FontAwesomeIcon
										icon={faEllipsis}
										className='text-[2rem]'
									/>
								</span>
							</a>
						</li>
					))}
			</ul>

			<ModalSong
				isOpen={isModalOpen}
				onClose={closeModal}
				musicInformation={songInformation}
			/>
		</>
	);
};

export default Search;
