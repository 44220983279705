/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useEffect, useState} from 'react';

import {useMusicProvider} from './context';
import ProgressBar from './components/progressBar';
import Information from './components/information';
import TopBar from './components/topBar';
import Background from './components/background';
import ModalSong from '../../components/features/modalSong';
import useCanPlay from './hook/canPlay';
import usePlayAudio from './hook/playAudio';
import {api} from '../../utils';
import {
	updateTime,
	updateMediaSession,
	PlayAudio,
	onAudioEnded,
	setCanPlayAndStart,
} from './function/audio';
const MusicBoard = () => {
	const audioRef = useRef(null);
	const containerRef = useRef(null);
	const {musicBoard, setMusicBoard} = useMusicProvider();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);
	usePlayAudio(audioRef, musicBoard, setMusicBoard);

	const handleClick = () => {
		setMusicBoard((prevState) => ({
			...prevState,
			isExpanded: !prevState.isExpanded,
		}));
	};

	useEffect(() => {
		if (audioRef.current && musicBoard.url.length > 0) {
			audioRef.current.src = musicBoard.url;
			audioRef.current.load();
		}
	}, [musicBoard.url]);

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current
				.play()
				.then(() => {
					setMusicBoard((prevState) => ({
						...prevState,
						isPlaying: true,
					}));
				})
				.catch((error) => {
					setMusicBoard((prevState) => ({
						...prevState,
						isPlaying: false,
					}));
				});
		}
	}, [audioRef.current]);

	useEffect(() => {
		if (!audioRef.current) return;

		const handlePlay = () => {
			setMusicBoard((prev) => ({...prev, isPlaying: true}));
		};

		const handlePause = () => {
			setMusicBoard((prev) => ({...prev, isPlaying: false}));
		};

		const handleTimeUpdate = () => {
			updateTime({
				audioRef: audioRef,
				setMusicBoard: setMusicBoard,
				musicBoard: musicBoard,
			});
		};

		const handleMetadataLoad = (event) => {
			setCanPlayAndStart({
				audioRef: audioRef,
				setMusicBoard: setMusicBoard,
				musicBoard: musicBoard,
			});
			updateMediaSession({
				audioRef: audioRef,
				setMusicBoard: setMusicBoard,
				musicBoard: musicBoard,
			});
		};

		const handleEnded = () => {
			onAudioEnded({
				audioRef: audioRef,
				setMusicBoard: setMusicBoard,
				musicBoard: musicBoard,
			});
		};

		audioRef.current.addEventListener('play', handlePlay);
		audioRef.current.addEventListener('pause', handlePause);
		audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
		audioRef.current.addEventListener('loadedmetadata', handleMetadataLoad);
		audioRef.current.addEventListener('ended', handleEnded);

		return () => {
			if (audioRef.current) {
				audioRef.current.removeEventListener('play', handlePlay);
				audioRef.current.removeEventListener('pause', handlePause);
				audioRef.current.removeEventListener(
					'loadedmetadata',
					handleMetadataLoad
				);
				audioRef.current.removeEventListener(
					'timeupdate',
					handleTimeUpdate
				);

				audioRef.current.removeEventListener('ended', handleEnded);
			}
		};
	}, [musicBoard]);

	return (
		<>
			<div
				className={`fixed ${!musicBoard.musicId && 'hidden'} ${
					musicBoard.isExpanded
						? `inset-0`
						: `bottom-20 md:bottom-[0.03rem] left-0 bg-secondary`
				} w-full text-white  flex flex-col items-center bg-opacity-90 backdrop-blur-lg z-50`}
				ref={containerRef}
				onClick={() => {
					!musicBoard.isExpanded && handleClick();
				}}
			>
				<div
					className={`w-full h-1 bg-secondary-100 mx-4 cursor-pointer absolute top-0 ${
						musicBoard.isExpanded && 'hidden'
					}`}
				>
					<div
						className='h-full bg-green-500'
						style={{width: `${musicBoard.progress}%`}}
					/>
				</div>
				<audio
					ref={audioRef}
					type='audio/webm'
					preload='auto'
					style={{display: 'none'}}
				>
					Votre navigateur ne supporte pas l'élément audio.
				</audio>
				{musicBoard.isExpanded && <Background />}
				{musicBoard.isExpanded && <TopBar openModal={openModal} />}
				<Information audioRef={audioRef || null} />
				{musicBoard.isExpanded && (
					<ProgressBar audioRef={audioRef || null} />
				)}
				<ModalSong
					isOpen={isModalOpen}
					onClose={closeModal}
					musicInformation={{
						title: musicBoard.videoInformation.title,
						author: musicBoard.videoInformation.author,
						thumbnail: `${api.thumbnail}${musicBoard.musicId}`,
						musicId: musicBoard.musicId,
					}}
				/>
			</div>
		</>
	);
};

export default MusicBoard;
