import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Dashboard from './components/features/dashboard/dashboard';
import Search from './components/features/search/search';
import Account from './components/features/account/account';
import Library from './components/features/library/library';
import Artist from './components/features/artist/artist';
import {
	BrowserRouter,
	Routes, // instead of "Switch"
	Route,
} from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<Routes>
			<Route path='/' element={<App component={<Dashboard />} />} />
			<Route path='/search' element={<App component={<Search />} />} />
			<Route path='/library' element={<App component={<Library />} />} />
			<Route
				path='/library/:id?'
				element={<App component={<Library />} />}
			/>
			<Route
				path='/artist/:id?'
				element={<App component={<Artist />} />}
			/>
			<Route path='/account' element={<App component={<Account />} />} />
		</Routes>
	</BrowserRouter>
);
