import React, {useEffect, useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay, faPause, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {useMusicProvider} from '../context';
import {PlayHandler} from '../function/audio';
const Information = ({audioRef}) => {
	const {musicBoard, setMusicBoard} = useMusicProvider();
	const imgRef = useRef(null);
	const canvasRef = useRef(null);

	useEffect(() => {
		const fetchImageAndExtractColor = async () => {
			try {
				if (imgRef.current) {
					const response = await fetch(
						musicBoard.videoInformation.thumbnail
					);
					const blob = await response.blob();
					const imageObjectURL = URL.createObjectURL(blob);
					imgRef.current.src = imageObjectURL;

					imgRef.current.onload = () => {
						extractDominantColor();
					};
				}
			} catch (error) {
				console.error(
					"Erreur lors du fetch de l'image ou de l'extraction des couleurs :",
					error
				);
			}
		};

		const extractDominantColor = () => {
			const canvas = canvasRef.current;
			const ctx = canvas.getContext('2d');
			const img = imgRef.current;
			canvas.width = img.width;
			canvas.height = img.height;
			ctx.drawImage(img, 0, 0, img.width, img.height);
			const imageData = ctx.getImageData(0, 0, img.width, img.height);
			const pixels = imageData.data;

			let r = 0,
				g = 0,
				b = 0;
			let totalPixels = 0;

			for (let i = 0; i < pixels.length; i += 4) {
				r += pixels[i];
				g += pixels[i + 1];
				b += pixels[i + 2];
				totalPixels++;
			}

			r = Math.floor(r / totalPixels);
			g = Math.floor(g / totalPixels);
			b = Math.floor(b / totalPixels);

			setMusicBoard((prevState) => ({
				...prevState,
				bgColor: `rgb(${r}, ${g}, ${b}, 66)`,
			}));
		};

		fetchImageAndExtractColor();
	}, [musicBoard.videoInformation.thumbnail]);

	const togglePlayPause = async () => {
		if (musicBoard.canPlay === false) return false;
		try {
			PlayHandler({
				audioRef: audioRef,
				musicBoard: musicBoard,
				setMusicBoard: setMusicBoard,
			});
		} catch (error) {
			console.error('Error playing or pausing audio:', error);
		}
	};

	const handleSeek = (e) => {
		const seekTime =
			(e.nativeEvent.offsetX / e.target.offsetWidth) *
			musicBoard.videoInformation.duration;
		audioRef.current.currentTime = seekTime;
		setMusicBoard((prevState) => ({
			...prevState,
			currentTime: seekTime,
			progress: (seekTime / musicBoard.videoInformation.duration) * 100,
		}));
	};

	return (
		<>
			<div
				className={`w-full md:w-[35em] flex flex-col items-center rounded-lg relative`}
			>
				{musicBoard.isExpanded && (
					<>
						<div className='w-full max-w-[35em] aspect-square rounded-3xl overflow-hidden p-4'>
							<img
								src={musicBoard.videoInformation.thumbnail}
								alt='thumbnail'
								className='w-full h-full object-cover select-none rounded-3xl shadow-1xl'
							/>
						</div>
					</>
				)}
				<div className='flex items-center justify-between w-full left-0 cursor-pointer select-none'>
					<div className='flex items-center pt-2 pl-1'>
						{!musicBoard.isExpanded && (
							<div className='mr-2 m-1'>
								<div className='relative inline-block'>
									<img
										src={
											musicBoard.videoInformation
												.thumbnail
										}
										alt='thumbnail'
										className='w-12 max-w-12 max-h-12 h-12 object-cover rounded-lg'
									/>

									{musicBoard.canPlay === false && (
										<div className='absolute inset-0 flex items-center justify-center'>
											<FontAwesomeIcon
												icon={faSpinner}
												className='animate-spin h-5 w-5 text-white'
											/>
										</div>
									)}
								</div>
							</div>
						)}

						<div className='flex flex-col'>
							<div
								className={`wrapper overflow-hidden ${
									musicBoard.isExpanded
										? 'max-w-72 md:max-w-[30rem] ml-4'
										: 'max-w-56 ml-2'
								} text-base font-semibold break-words hyphens-auto`}
							>
								<div className='marquee whitespace-nowrap inline-block animate-marquee'>
									<p className='inline-block mr-4'>
										{musicBoard.videoInformation.title}
									</p>
									<p className='inline-block'>
										{musicBoard.videoInformation.title}
									</p>
								</div>
							</div>

							<p
								className={`text-sm text-gray-400  ${
									musicBoard.isExpanded ? 'ml-4' : 'ml-2'
								}`}
							>
								{musicBoard.videoInformation.author}
							</p>
						</div>
					</div>
					<button
						onClick={(e) => {
							e.stopPropagation();
							togglePlayPause();
						}}
						className={`${
							musicBoard.canPlay ? 'text-white' : 'text-gray-700'
						} p-4 rounded-full center ml-auto text-xl ${
							musicBoard.isExpanded && 'hidden'
						}`}
					>
						{musicBoard.canPlay === false ? (
							<FontAwesomeIcon
								icon={faSpinner}
								className='animate-spin w-6 text-white'
							/>
						) : (
							<FontAwesomeIcon
								icon={musicBoard.isPlaying ? faPause : faPlay}
								className='w-6'
							/>
						)}
					</button>
				</div>
			</div>

			<canvas ref={canvasRef} style={{display: 'none'}} />
			<img
				ref={imgRef}
				alt='X'
				crossOrigin='anonymous'
				style={{display: 'none'}}
			/>
		</>
	);
};

export default Information;
