/* eslint-disable react/no-unescaped-entities */
import React, {useEffect, useState} from 'react';
import {useMusicProvider} from '../../musicBoard/context';
import {toast} from 'react-toastify';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {api} from '../../../utils';
import Modal from '../../../components/modal';
import {
	faPlus,
	faHeart,
	faCirclePlay,
	faUser,
	faMusic,
} from '@fortawesome/free-solid-svg-icons';
const ModalEditPlaylist = ({isOpen, onClose, playlistInformation}) => {
	const {musicBoard} = useMusicProvider();
	const [modalInformation, setModalInformation] = useState({
		inputNamePlaylist: '',
	});

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<div className='relative flex flex-col h-full'>
				{/* Section de l'image en haut avec overlay */}
				<div className='relative w-full h-56 overflow-hidden flex-shrink-0'>
					{playlistInformation.lastSondId && (
						<img
							src={`${api.thumbnail}${playlistInformation.lastSondId}`}
							alt='thumbnail'
							className='w-full h-full object-cover'
						/>
					)}
					{/* Overlay avec dégradé gris */}
					<div className='absolute inset-0 bg-gradient-to-b from-transparent to-[#282828] opacity-90'></div>
					<div className='absolute inset-0 flex items-center justify-center'>
						<span className='text-white text-xl font-semibold bg-black bg-opacity-70 px-4 py-2 rounded'>
							{playlistInformation.name}
						</span>
					</div>
				</div>

				{/* Contenu principal au-dessus du bouton Close */}
				<div className='flex flex-col gap-4 pt-6 items-center mb-auto'>
					<div className='flex flex-nowrap'>
						<div className='w-14 items-center justify-center'>
							<span className='text-2xl'>
								<FontAwesomeIcon icon={faHeart} />
							</span>
						</div>
						<span className='font-medium w-full mt-1 text-left'>
							Delete Playlist
						</span>
					</div>

					<div className='flex flex-nowrap' onClick={() => {}}>
						<div className='w-14 items-center justify-center'>
							<span className='text-2xl'>
								<FontAwesomeIcon icon={faPlus} />
							</span>
						</div>
						<span className='font-medium w-full mt-1 text-left'>
							Edit Playlist
						</span>
					</div>
				</div>

				{/* Bouton Close en bas de la modal */}
				<div className='p-4'>
					<button
						onClick={onClose}
						className='w-full py-2 bg-red-500 text-white font-semibold rounded-md hover:bg-red-600 transition duration-200'
					>
						Close
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default ModalEditPlaylist;
