/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useEffect, useState} from 'react';
import Login from './components/login';
import Register from './components/register';
const Auth = () => {
	const [isLogin, setIsLogin] = useState(true);

	const isLoginHandle = (bool) => {
		setIsLogin(bool);
	};

	return (
		<>
			<h1 className='text-center text-2xl font-bold tracking-wide not-italic'>
				Welcome Back !
			</h1>
			<div className='max-w-xl mx-auto flex justify-center items-center rounded-lg shadow-sm space-x-2 mt-6'>
				<button
					className={`${
						isLogin === true
							? 'bg-green-500 hover:bg-green-600 border-green-500 shadow shadow-green-500'
							: 'bg-secondary-500 hover:bg-secondary-600 border-secondary-500'
					} text-sm border-4 text-white py-2 px-3 w-full rounded font-bold tracking-wide`}
					type='button'
					onClick={() => {
						isLoginHandle(true);
					}}
				>
					Login
				</button>
				<button
					className={`${
						isLogin === false
							? 'bg-green-500 hover:bg-green-600 border-green-500 shadow shadow-green-500'
							: 'bg-secondary-500 hover:bg-secondary-600 border-secondary-500'
					} text-sm border-4 text-white py-2 px-3 w-full rounded font-bold tracking-wide`}
					type='button'
					onClick={() => {
						isLoginHandle(false);
					}}
				>
					Register
				</button>
			</div>

			{isLogin ? <Login /> : <Register />}
		</>
	);
};

export default Auth;
