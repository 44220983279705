export const api = {
	base: 'https://api-groove.anatik.pro/api/',
	thumbnail: 'https://api-groove.anatik.pro/api/thumbnail/',
};

export async function fetchData(
	path,
	method = 'GET',
	body = null,
	headers = {}
) {
	const options = {
		method: method.toUpperCase(),
		headers: {
			'Content-Type': 'application/json',
			...headers,
		},
		body: body ? JSON.stringify(body) : null,
	};

	console.log(options);

	if (method.toUpperCase() === 'GET' || method.toUpperCase() === 'HEAD') {
		delete options.body;
	}

	try {
		const response = await fetch(`${api.base}${path}`, options);
		console.log(response);

		return await response.json();
	} catch (error) {
		console.error('Fetch error:', error);
		throw error;
	}
}
