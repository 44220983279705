import {useEffect, useRef} from 'react';
import {api} from '../../../utils';
const usePlayAudio = (audioRef, musicBoard, setMusicBoard) => {
	useEffect(() => {
		try {
			if (!musicBoard.musicId) return;
			//if (!audioRef.current) return;

			/*
        Stock Last Audio ID
      */
			setMusicBoard((prevState) => ({
				...prevState,
				lastestSong: musicBoard.musicId,
			}));

			/*
        Reset Audio State & Fetch New Audio
      */
			if (musicBoard.musicId !== musicBoard.lastestSong) {
				if (audioRef.current) audioRef.current.pause();

				setMusicBoard((prevState) => ({
					...prevState,
					progress: 0,
					currentTime: 0,
					duration: 0,
					canPlay: false,
				}));

				fetch(`${api.base}play/${musicBoard.musicId}`, {
					headers: {
						'Content-Type': 'application/json',
						authorization: localStorage.getItem('token'),
					},
				})
					.then((response) => response.json())
					.then((dataVideo) => {
						setMusicBoard((prevState) => ({
							...prevState,
							url: dataVideo.url,
							videoInformation: dataVideo,
						}));

						setTimeout(() => {
							if (audioRef.current) audioRef.current.load();
						}, 1000);
					});
			}
		} catch (error) {
			alert(error);
		}
	}, [
		musicBoard.musicId,
		musicBoard.url,
		musicBoard.isPlaying,
		musicBoard.lastestSong,
		musicBoard.videoInformation.title,
		musicBoard.videoInformation.author,
		musicBoard.videoInformation.thumbnail,
	]);
};

export default usePlayAudio;
