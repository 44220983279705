import React from 'react';
import {useMusicProvider} from '../../../features/musicBoard/context';
const LatestSongArtist = () => {
	const {setMusicBoard} = useMusicProvider();
	const changeTrack = () => {
		setMusicBoard((prevState) => ({
			...prevState,
			musicId: 'cojoYPRcIJA',
		}));
	};
	const changeTrack2 = () => {
		setMusicBoard((prevState) => ({
			...prevState,
			musicId: 'KwNoMTOO9Qk',
		}));
	};
	return (
		<div className='flex flex-wrap gap-4 card-container p-2'>
			<div
				className='flex-1 min-w-[calc(50%-1rem)] bg-secondary rounded-lg shadow-lg p-2 card'
				onClick={changeTrack}
			>
				<div className='flex items-center'>
					<div className='flex-shrink-0'>
						<img
							className='w-14 h-14 rounded-full object-cover aspect-square	'
							src='https://i.ytimg.com/vi/xk1c02v3U_U/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLDDt5Rn4-0YBqfzNhyEC7EVdVbpyg'
						/>
					</div>
					<div className='flex-1  ms-2'>
						<p className='auto-size-text text-gray-200 truncate dark:text-gray-200'>
							Ziak
						</p>
					</div>
				</div>
			</div>

			<div
				className='flex-1 min-w-[calc(50%-1rem)] bg-secondary rounded-lg shadow-lg p-2 card'
				onClick={changeTrack2}
			>
				<div className='flex items-center'>
					<div className='flex-shrink-0'>
						<img
							className='w-14 h-14 rounded-full aspect-square	 object-cover'
							src='https://strikt.net/wp-content/uploads/2021/11/menace-santana.png'
							alt='Neil image'
						/>
					</div>
					<div className='flex-1  ms-2'>
						<p className='auto-size-text text-gray-200  dark:text-gray-200'>
							Menace Santanas
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LatestSongArtist;
