import React, {useState, useRef, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faHouse,
	faMagnifyingGlass,
	faMusic,
	faUser,
} from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
	const navigate = useNavigate();
	const [locationHref, setLocationHref] = useState('/');

	useEffect(() => {
		setLocationHref(location.pathname);
	}, [location.pathname]);
	return (
		<>
			<nav className='hidden md:flex fixed top-0 left-0 w-full bg-secondary text-white p-4  justify-between items-center z-100	'>
				<div className='text-lg font-bold'>
					<h3>Groovy</h3>
				</div>
				<div className='flex space-x-4'>
					<p className='hover:underline cursor-pointer'>Accueil</p>
					<p className='hover:underline cursor-pointer'>Recherche</p>
					<p className='hover:underline cursor-pointer'>
						Blibliothèque
					</p>
					<p className='hover:underline cursor-pointer'>Account</p>
				</div>
			</nav>
			<nav className='md:hidden fixed bottom-0 left-0 w-full text-white p-4  flex justify-around items-center bg-secondary bg-opacity-60 backdrop-blur-lg space-x-4 z-100	select-none'>
				<div
					className='flex-1 text-center'
					onClick={() => {
						navigate('/');
					}}
				>
					<FontAwesomeIcon
						icon={faHouse}
						className={`${
							locationHref === '/' && 'text-green-500'
						} mb-1`}
					/>
					<p
						className={`${
							locationHref === '/' && 'text-green-500 font-medium'
						}`}
					>
						Accueil
					</p>
				</div>

				<div
					className='flex-1 text-center'
					onClick={() => {
						navigate('/search');
					}}
				>
					<FontAwesomeIcon
						icon={faMagnifyingGlass}
						className={`${
							locationHref === '/search' && 'text-green-500'
						} mb-1`}
					/>
					<p
						className={`${
							locationHref === '/search' &&
							'text-green-500 font-medium'
						}`}
					>
						Recherche
					</p>
				</div>

				<div
					className='flex-1 text-center'
					onClick={() => {
						navigate('/library');
					}}
				>
					<FontAwesomeIcon
						icon={faMusic}
						className={`${
							locationHref === '/library' && 'text-green-500'
						} mb-1`}
					/>
					<p
						className={`${
							locationHref === '/library' &&
							'text-green-500 font-medium'
						}`}
					>
						Blibliothèque
					</p>
				</div>

				<div
					className='flex-1 text-center'
					onClick={() => {
						navigate('/account');
					}}
				>
					<FontAwesomeIcon
						icon={faUser}
						className={`${
							locationHref === '/account' && 'text-green-500'
						} mb-1`}
					/>
					<p
						className={`${
							locationHref === '/account' &&
							'text-green-500 font-medium'
						}`}
					>
						Account
					</p>
				</div>
			</nav>
		</>
	);
};

export default Navbar;
