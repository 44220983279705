import React, {useState, useEffect} from 'react';
import {useMusicProvider} from '../../musicBoard/context';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faPlus,
	faBook,
	faMusic,
	faEllipsis,
} from '@fortawesome/free-solid-svg-icons';
import {fetchData, api} from '../../../utils';
import {useNavigate} from 'react-router-dom';
import ModalCreatePlaylist from './modalCreatePlaylist';
import ModalEditPlaylist from './modal';
const PlaylistList = () => {
	const {setMusicBoard} = useMusicProvider();
	const navigate = useNavigate();
	// Modal Create Playlist
	const [isModalOpen, setIsModalOpen] = useState(false);
	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);
	// Modal Edit Playlist
	const [isModalOpen2, setIsModalOpen2] = useState(false);
	const openModal2 = () => setIsModalOpen2(true);
	const closeModal2 = () => setIsModalOpen2(false);
	const [editPlaylist, setEditPlaylist] = useState({
		name: '',
		id: '',
		lastSondId: '',
	});

	const PlaylistSettings = (name, id, lstSong) => {
		setEditPlaylist((prevState) => ({
			...prevState,
			name: name,
			id: id,
			lastSondId: lstSong,
		}));
		openModal2();
	};

	const [playlist, setPlaylist] = useState({
		playlistRow: {},
	});

	const fetchRow = async () => {
		const getData = await fetchData('library', 'GET', null, {
			authorization: localStorage.getItem('token'),
		});
		if (getData.code === 200) {
			setPlaylist((prevState) => ({
				...prevState,
				playlistRow: getData.playlists,
			}));
			localStorage.setItem('playlist', JSON.stringify(getData.playlists));
		}
	};

	useEffect(() => {
		fetchRow();
	}, []);

	const AddPlaylist = async (id) => {
		const getData = await fetchData('playlist/' + id, 'GET', null, {
			authorization: localStorage.getItem('token'),
		});

		const updatedSongs = [...getData.song.slice(1), getData.song[0]];

		localStorage.setItem('playlistNow', JSON.stringify(updatedSongs));
		console.log(updatedSongs[0].songId);

		setMusicBoard((prevState) => ({
			...prevState,
			musicId: updatedSongs[0].songId,
			playlist: id,
			playlistSong: updatedSongs,
		}));
	};

	return (
		<>
			<div className='my-4 space-y-3'>
				<h2 className='text-xl font-semibold mb-4'>Library</h2>
				<div>
					<a
						className='flex items-center p-3 text-base font-bold rounded-lg bg-secondary-700 hover:bg-secondary-600'
						onClick={() => {
							openModal();
						}}
					>
						<div className='flex items-center m-4 cursor-pointer select-none'>
							<FontAwesomeIcon
								icon={faPlus}
								className='w-8 h-8 mr-3 object-cover rounded-lg'
							/>

							<div className='flex flex-col'>
								<h3 className='text-lg font-semibold break-words hyphens-auto max-w-64'>
									Create New Playlist
								</h3>
							</div>
						</div>
					</a>
				</div>
				<h3 className='text-xl font-semibold mb-4'>Playlists</h3>
				<div className='space-y-3'>
					{playlist.playlistRow.length >= 1 &&
						playlist.playlistRow
							.sort((a, b) => b.songCount - a.songCount) // Trie par songCount décroissant
							.map((item, key) => (
								<a
									key={key}
									className='flex items-center p-3 text-base font-bold rounded-lg bg-secondary-700'
									onClick={() => {
										// AddPlaylist(item.id);
										navigate('/library/' + item.id);
									}}
								>
									<div className='flex items-center m-4 cursor-pointer select-none'>
										{item.lastSongId !== '' ? (
											<img
												src={`${api.thumbnail}${item.lastSongId}`}
												alt={`thumbnail-test`}
												className='w-14 h-14 mr-3 object-cover rounded-lg'
											/>
										) : (
											<FontAwesomeIcon
												icon={faMusic}
												className='w-8 h-8 mr-3 object-cover rounded-lg'
											/>
										)}

										<div className='flex flex-col'>
											<h3 className='text-lg font-semibold break-words hyphens-auto max-w-64'>
												{item.name}
											</h3>
											<p className='text-sm text-gray-400'>
												{item.songCount} Songs
											</p>
										</div>
									</div>
									<span
										className='ml-auto inline-flex items-center justify-center px-2 py-0.5 text-xs font-medium text-gray-500 rounded'
										onClick={(e) => {
											e.stopPropagation();
											PlaylistSettings(
												item.name,
												item.id,
												item.lastSongId
											);
										}}
									>
										<FontAwesomeIcon
											icon={faEllipsis}
											className='text-[2rem]'
										/>
									</span>
								</a>
							))}
				</div>
			</div>
			<ModalCreatePlaylist
				isOpen={isModalOpen}
				onClose={closeModal}
				event={fetchRow}
			/>

			<ModalEditPlaylist
				isOpen={isModalOpen2}
				onClose={closeModal2}
				playlistInformation={editPlaylist}
			/>
		</>
	);
};

export default PlaylistList;
