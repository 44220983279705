/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const Modal = ({isOpen, onClose, children}) => {
	if (!isOpen) return null;

	return (
		<div
			className={`fixed inset-0 z-[60] flex items-center justify-center bg-gray-900 bg-opacity-50 transition-opacity duration-300 ease-out ${
				isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
			}`}
			onClick={onClose}
		>
			<div
				className={`bg-secondary bg-opacity-100 shadow-lg  w-full h-full  relative transform transition-transform duration-500 ease-out ${
					isOpen ? 'translate-y-0' : '-translate-y-full'
				}`}
				onClick={(e) => e.stopPropagation()}
			>
				<button
					className='absolute top-2 -right-1 text-gray-500 hover:text-gray-800'
					onClick={onClose}
				>
					<svg
						className='w-6 h-6'
						fill='none'
						stroke='currentColor'
						viewBox='0 0 24 24'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth={2}
							d='M6 18L18 6M6 6l12 12'
						/>
					</svg>
				</button>
				{children}
			</div>
		</div>
	);
};

export default Modal;
