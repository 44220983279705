import React, {createContext, useState, useContext} from 'react';
const MusicContext = createContext('');

export const MusicProvider = ({children}) => {
	const [musicBoard, setMusicBoard] = useState({
		musicId: '',
		playlist: 0,
		playlistSong: {},
		volume: 0.5,
		isMobile: false,
		isPlaying: false,
		canPlay: false,
		autoPlayNext: false,
		progress: 0,
		currentTime: 0,
		duration: 0,
		isExpanded: false,
		lastestSong: false,
		videoInformation: {},
		lastSong: [],
		infinite: false,
		shuffle: false,
		bgColor: '',
		url: '',
	});

	return (
		<MusicContext.Provider value={{musicBoard, setMusicBoard}}>
			{children}
		</MusicContext.Provider>
	);
};

export function useMusicProvider() {
	return useContext(MusicContext);
}
