/* eslint-disable react/no-unescaped-entities */
import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {api, fetchData} from '../../utils';

import {
	faPlus,
	faMinus,
	faHeart,
	faCirclePlay,
	faUser,
	faMusic,
} from '@fortawesome/free-solid-svg-icons';
import Modal from '../modal';
const ModalSong = ({isOpen, onClose, musicInformation, eventCallback}) => {
	const [modalInformation, setModalInformation] = useState({
		action: 1,
	});

	const setAction = (action) => {
		setModalInformation((prevState) => ({
			...prevState,
			action: action,
		}));
	};

	const RemoveFromThePlaylist = async () => {
		const getData = await fetchData(
			'playlist/' +
				musicInformation.playlist +
				'/' +
				musicInformation.musicId,
			'DELETE',
			{},
			{
				authorization: localStorage.getItem('token'),
			}
		);

		if (getData.code === 200) {
			eventCallback?.();
			toast.success('The song has been deleted from your playlist !');
		}
	};

	const AddSongToPlaylist = async (playlist, songId) => {
		const getData = await fetchData(
			'playlist/' + playlist + '/' + songId,
			'PUT',
			{},
			{
				authorization: localStorage.getItem('token'),
			}
		);
		if (getData.code === 200) {
			toast.success('The song has been added to your playlist !');
		}
		if (getData.code === 409) {
			toast.error('The song is already in this playlist !');
		}
	};
	const playlist = JSON.parse(localStorage.getItem('playlist'));

	const AddNext = (musicId, title) => {
		const storedItems = localStorage.getItem('items');
		let itemsArray = storedItems ? JSON.parse(storedItems) : [];
		itemsArray.unshift(musicId);
		toast.success(`${title} has been Added to your playlist !`);
		localStorage.setItem('items', JSON.stringify(itemsArray));
	};
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<div className='p-4  flex flex-col'>
				<div className='m-6 flex flex-col justify-center items-center'>
					<img
						src={musicInformation.thumbnail}
						alt='thumbnail'
						className='w-44 h-44 object-cover rounded-full mb-4'
					/>

					<div
						className={`wrapper overflow-hidden w-full text-lg font-semibold break-words hyphens-auto max-w-64  ml-2`}
					>
						<div className='marquee whitespace-nowrap inline-block animate-marquee'>
							<p className='inline-block mr-8'>
								{musicInformation.title}
							</p>
							<p className='inline-block'>
								{musicInformation.title}
							</p>
						</div>
					</div>

					<p className='text-gray-200 mb-4'>
						{musicInformation.author}
					</p>
				</div>

				{modalInformation.action === 1 ? (
					<div className='flex flex-col gap-4 items-center'>
						<div className='flex flex-nowrap'>
							<div className='w-14 items-center justify-center'>
								<span className='text-2xl'>
									<FontAwesomeIcon icon={faHeart} />
								</span>
							</div>
							<span className='font-medium w-full mt-1'>
								Ajouter aux Favoris
							</span>
						</div>

						<div
							className='flex flex-nowrap'
							onClick={() => {
								setAction(2);
							}}
						>
							<div className='w-14 items-center justify-center'>
								<span className='text-2xl'>
									<FontAwesomeIcon icon={faPlus} />
								</span>
							</div>
							<span className='font-medium w-full mt-1'>
								Ajouter à une Playlist
							</span>
						</div>

						{musicInformation.playlist && (
							<div
								className='flex flex-nowrap'
								onClick={() => {
									RemoveFromThePlaylist();
								}}
							>
								<div className='w-14 items-center justify-center'>
									<span className='text-2xl'>
										<FontAwesomeIcon icon={faMinus} />
									</span>
								</div>
								<span className='font-medium w-full mt-1'>
									Retirer de cette Playlist
								</span>
							</div>
						)}

						<div
							className='flex flex-nowrap'
							onClick={() => {
								AddNext(
									musicInformation.musicId,
									musicInformation.title
								);
							}}
						>
							<div className='w-14 items-center justify-center'>
								<span className='text-2xl'>
									<FontAwesomeIcon icon={faCirclePlay} />
								</span>
							</div>
							<span className='font-medium w-full mt-1'>
								Ajouter à la liste d'écoute
							</span>
						</div>

						<div className='flex flex-nowrap'>
							<div className='w-14 items-center justify-center'>
								<span className='text-2xl'>
									<FontAwesomeIcon icon={faUser} />
								</span>
							</div>
							<span className='font-medium w-full mt-1'>
								Voir la page de l'artiste
							</span>
						</div>
					</div>
				) : playlist && playlist.length > 0 ? (
					playlist.map((item, key) => (
						<a
							key={key}
							className='flex items-center p-3 mt-2 text-base font-bold rounded-lg bg-secondary-700'
							onClick={() => {
								AddSongToPlaylist(
									item.id,
									musicInformation.musicId
								);
								setAction(1);
							}}
						>
							<div className='flex items-center m-2 cursor-pointer select-none'>
								{item.lastSongId !== '' ? (
									<img
										src={`${api.thumbnail}${item.lastSongId}`}
										alt={`thumbnail-test`}
										className='w-14 h-14 mr-3 object-cover rounded-lg'
									/>
								) : (
									<FontAwesomeIcon
										icon={faMusic}
										className='w-14 h-14 mr-3 object-cover rounded-lg'
									/>
								)}

								<h3 className='text-lg font-semibold break-words hyphens-auto max-w-64'>
									{item.name}
								</h3>
							</div>
						</a>
					))
				) : null}
			</div>
		</Modal>
	);
};

export default ModalSong;
